<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h5 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp;Process's List</h5>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="process-table"
             class="table table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="name" >Name</th>
          <th data-field="nature" data-formatter="processNatureFormatter" >Nature</th>
          <th data-field="ledger.name" >Ledger</th>
          <th data-field="s_name" >Prefix</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->


    <!--    Receipt Modal-->
    <div class="modal" id="process-window" >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <ProcessForm  v-if="showwindow" v-bind:myprocess="process" v-on:process_window_closed="closeModal" v-on:process_window_saved="loadData" ></ProcessForm>
          </div>


        </div>
      </div>
    </div>
    <!--    Receipt Modal-->


  </div>

</template>

<script>
  import ProcessForm from '@/components/v1/tms/process/ProcessForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'

  export default {
    name: 'ProcessView',
    components: {
      ProcessForm,
      FabButton
    },
    data () {
      return {
        showwindow:false,
        mytable: {},
        process: JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}'),
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#process-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#fcc-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        this.$data.showwindow = false;
        $('#process-window').modal('hide');
        this.$data.process = JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}');
      },
      showModal () {
        this.$data.showwindow = true;
        $('#process-window').modal('show');
      },
      loadData () {
        this.$data.showwindow = false;
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#process-window').modal('hide');
        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.mytable.bootstrapTable('load',[]);
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/after/404/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });

      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.process = JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.process = data.data;
            self.$data.showwindow = true;
            $('#process-window').modal('show')
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.process.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.process)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('process_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
