<template>

  <div class="card">

    <div class="card-header header-elements-inline bg-success-800" >
      <h5 class="card-title">Process Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="process" v-model="process.name" >
          </div>
        </div>

        <!--<div class="form-group row">-->
          <!--<label class="col-md-2 col-form-label font-weight-semibold">Print Name:</label>-->
          <!--<div class="col-md-10">-->
            <!--<input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="process" v-model="process.p_name">-->
          <!--</div>-->
        <!--</div>-->

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Nature:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="process" v-model="process.nature">
              <option value="0" selected>Unknown</option>
              <option value="1" selected>Yarn</option>
              <option value="2" selected>Fabric</option>
              <option value="3" selected>MadeUp</option>
            </select>
          </div>
          <label class="col-md-2 col-form-label font-weight-semibold">Prefix:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Short Prefix here..." maxlength="100" v-if="process" v-model="process.s_name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Ledger:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="process" v-model="process.ledger.code">
              <option v-for="ledger in ledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <textarea id="content-editor" v-if="process" v-model="process.print_data" > </textarea>
        </div>
      </form>
    </div>

    <div class="card-footer" style="padding-top: 0px;padding-left: 0px;padding-left: 0px;">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-success" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'

  export default {
    name: 'ProcessForm',
    data () {
      return {
        process: JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}'),
        ledgers:[],

      }
    },
    component: {
      FabButton,
    },
    props: {
      myprocess: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}')
      }
    },
    beforeMount () {
      this.process = this.myprocess; // save props data to itself's data and deal with tms
    },
    created () {
      this.$data.process = JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}')
    },
    mounted () {
      const self = this;
      if (tinymce.get('content-editor') !== null) {
        tinyMCE.remove();
      }
      tinymce.init({
        selector: 'textarea',
        width: '100%',
        height: 500,
        plugins: 'print preview fullpage code powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons',
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
        menubar: 'file edit view insert format tools table tc',
        init_instance_callback: function (editor) {
          $('.tox-statusbar__branding').remove();
          editor.on('blur', function (e) {
            $("span").remove(".tox-statusbar__branding");
          });
        },

      });

      self.loadLedgers();

      // self.$data.test = 'Suba';
      // let m = {'test':'Suba', 'test1':'Ragu'};
      // let template = Handlebars.compile(`<div class="entry">
      //   <h3>{{test}}</h3>
      //   <h1>{{test1}}</h1>
      // </div>`);
      //
      // let result = template(m);

    },
    beforeDestroy(){
      if (tinymce.get('content-editor') !== null) {
        tinyMCE.remove();
      }
    },
    methods: {
      closeWindow() {
        this.$emit('process_window_closed');
      },
      loadLedgers(){
        const self = this;
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/group/27/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      saveDocument(){
        const self = this;

        // self.$data.test = 'Suba';
        // let m = {'test':'Suba'};
        // let template = Handlebars.compile(`<div class="entry">
        //   <h1>{{test}}</h1>
        // </div>`);
        //
        // let result = template(m);
        // alert(result);


        if(self.$data.process.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.process.s_name.trim().length < 1){
          alert("Invalid Prefix");
          return
        }

        self.$data.process.nature = parseInt(self.$data.process.nature);
        self.$data.process.ledger.code = parseInt(self.$data.process.ledger.code);
        self.$data.process.print_data = tinymce.get('content-editor').getContent();

        const requestOptions = {
          method:  (self.$data.process.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.process)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, type:  "success"} );
            self.process = JSON.parse('{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","print_data":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}}');
            self.$emit('process_window_saved');
          } else {
            swal ( { title: "Oops!" ,  text: data.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
